<template>
  <template v-for="(item, i) in storyList" :key="i">
    <section
      :id="'sec'+(i+1)"
      :class="{
        sec: 'sec',
        'bg-secondary': (i + 1) % 2 == 1,
        'bg-primary': (i + 1) % 2 == 0,
      }"
    >
      <div class="sec-deco-top deco-wave-primary">
        <img
          src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/deco-bird.png"
          class="deco bird"
        />
        <img
          src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-secondary.svg"
          class="img-fluid w-100 img-margin-control"
          style="transform: rotate(180deg);"
        />
      </div>
      <img
        src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mark-banner-secondary.svg"
        class="img-fluid sec-deco-bottom deco-wave-primary"
      />
      <div class="sec__content container">
        <div class="row">
          <div class="col-md-6" v-for="(items,index) in item" :key="`${i}-${index}`">
            <div
              :class="{
                cardfancy: 'cardfancy',
                'cardfancy--primary': (i + 1) % 2 == 1,
                'cardfancy--secondary': (i + 1) % 2 == 0,
              }"
            >
              <div class="cardfancy__imgbox" @mouseover="isHovering = `${i}-${index}`" @mouseleave="isHovering = -1">
                <div class="imgbox">
                  <div class="imgbox__inner">
                    <div
                      class="imgbox__image"
                      :class="{'scale': isHovering==`${i}-${index}`}"
                      :style="{ backgroundImage: 'url(' + items.Img + ')' }"
                    ></div>
                  </div>
                  <template v-if="isHovering==`${i}-${index}`">
                  <img
                    src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mask-cardfancy-secondary-hover.png"
                    class="cardfancy__mask mask--secondary img-fluid"
                  />
                  <img
                    src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mask-cardfancy-primary-hover.png"
                    class="cardfancy__mask mask--primary img-fluid"
                  />
                  </template>
                  <template v-else-if="isHovering!==`${i}-${index}`">
                  <img
                    src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mask-cardfancy-secondary.png"
                    class="cardfancy__mask mask--secondary img-fluid"
                  />
                  <img
                    src="https://kinpoweb.blob.core.windows.net/hci21/frontend/images/mask-cardfancy-primary.png"
                    class="cardfancy__mask mask--primary img-fluid"
                  />
                  </template>
                  <div class="cardfancy__tag bubble-tag bubble--size6x6" v-html="items.HciStoryClass">
                  </div>
                </div>
                <!-- <div class="circle"></div>
              <div class="circle circle2"></div> -->
              </div>
              <div class="cardfancy__textbox">
                <h2 class="cardfancy__title">{{ $filters.removeHtmlTag(items.Title) }}</h2>
                <p class="multiline-ellipsis">{{ items.SubTitle }}</p>
                <p class="d-flex justify-content-center">
                  <router-link
                    :to="'/' + this.$route.params.lang + `/story/${items.SeoUrl}`"
                    @click="clickRouterTop"
                    class="btn btn-pill btn-light d-flex justify-content-center"
                    >了解更多<span class="icon-arrow-right"><span></span><span></span></span> </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
</template>

<script>
export default {
  props: ["storyList", "storyListLength"],
  data() {
    return {
      isHovering: -1,
    }
  },
  methods: {
    clickRouterTop(){
      //chrome
      document.body.scrollTop = 0
      //firefox
      document.documentElement.scrollTop = 0
      //safari
      window.pageYOffset = 0
    }
  }
};
</script>

<style lang="scss" scoped>
.scale {
  transform: scale(1.5);
}

.sec {
  min-height: 40rem;
  padding-top: 0;
  @media (max-width: 576px) {
    padding-bottom: 6rem;
  }
}
.sec.bg-primary {
  min-height: 50rem;
}

.sec.bg-primary .cardfancy__imgbox {
  margin-top: 5rem;
}

.cardfancy {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 99;
  width: 520px;
  height: 385px;
  @media (max-width: 1200px) {
    width: 480px;
    height: auto;
  }
  @media (max-width: 1024px) {
    width: 440px;
  }
  @media (max-width: 992px) {
    width: 340px;
    margin: auto;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
  .cardfancy__imgbox {
    position: relative;
    margin-top: 1rem;
    .imgbox {
      border: none;
    }
    .imgbox__inner {
      padding-top: 72%;
      // @media (max-width: 1183px) {
      //   padding-top: 350px;
      // }
      // @media (max-width: 576px) {
      //   padding-top: 280px;
      // }
      // @media (max-width: 375px) {
      //   padding-top: 255px;
      // }
      // @media (max-width: 320px) {
      //   padding-top: 210px;
      // }
    }
    .cardfancy__mask {
      // width: 100%;
      // height: 100%;
      position: absolute;
      top: 0;
      z-index: 10;
    }
    .cardfancy__tag {
      position: absolute;
      left: 5%;
      bottom: 0;
      z-index: 30;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .cardfancy__textbox {
    padding: 2rem 16% 2rem;
    @media (max-width: 768px) {
      padding: 2rem 10% 2rem;
    }
    @media (max-width: 576px) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
    .cardfancy__title {
      font-size: 30px;
      font-weight: 700;
      line-height: 43.44px;
      padding-bottom: .3rem;
      margin-bottom: 1rem;
    }
  }
}

.deco.bird {
  height: 6rem;
  left: 46%;
  top: 22%;
  transform: translateX(-146%);
  z-index: 1;
  @media (max-width: 1440px) {
    top: -4%;
  }
  @media (max-width: 1366px) {
    left: 43%;
  }
  @media (max-width: 1024px) {
    top: -50%;
    left: 49%;
  }
  @media (max-width: 768px) {
    top: -95%;
    left: 60%;
  }
  @media (max-width: 576px) {
    top: -134%;
    transform: translateX(-100%);
    height: 70px;
  }
  @media (max-width: 320px) {
    top: -110%;
    height: 60px;
  }
}

.bg-primary > .deco-wave-primary {
  display: block;
}

.bg-secondary > .deco-wave-primary {
  display: none;
}

.cardfancy.cardfancy--primary {
  .cardfancy__imgbox {
    .cardfancy__tag {
      background: #F4D240;
    }
    .cardfancy__mask.mask--primary {
      display: none;
    }
  }
  .cardfancy__textbox {
    .cardfancy__title {
      border-bottom: 1px solid #fff;
    }
    .cardfancy__title,
    p {
      color: #fff;
    }
  }
}
.cardfancy.cardfancy--secondary {
  .cardfancy__imgbox {
    @media (max-width: 576px) {
      margin-top: 1.2rem;
    }
    .cardfancy__tag {
      background: $secondary;
      color: #fff !important;
      border-color: $secondary-light;
    }
  }
  .cardfancy__textbox {
    .cardfancy__title {
      border-bottom: 1px solid #333;
    }
    .cardfancy__title,
    p {
      color: #333;
    }
  }
}

//
.circle {
  width: 500px;
  height: 400px;
  position: absolute;
  top: 0;
  z-index: 20;
  border: 1px solid rgb(255 255 255 / 65%);
  border-radius: 55% 45% 30% 70% / 45% 60% 40% 55%;
  animation: wheel 10s infinite;
}

.circle2 {
  border-radius: 55% 45% 30% 70% / 45% 60% 40% 55%;
  animation: wheel2 10s infinite;
}

@keyframes wheel {
  0% {
    border-radius: 35% 65% 50% 50%/40% 30% 70% 60%;
  }
  25% {
    border-radius: 55% 45% 30% 70%/45% 60% 40% 55%;
  }
  50% {
    border-radius: 65% 35% 35% 65%/60% 55% 45% 40%;
  }
  75% {
    border-radius: 55% 45% 30% 70%/45% 60% 40% 55%;
  }
  100% {
    border-radius: 35% 65% 50% 50%/40% 30% 70% 60%;
  }
}

@keyframes wheel2 {
  0% {
    border-radius: 55% 45% 30% 70%/45% 60% 40% 55%;
  }
  50% {
    border-radius: 35% 65% 50% 50%/40% 30% 70% 60%;
  }
  100% {
    border-radius: 55% 45% 30% 70%/45% 60% 40% 55%;
  }
}

/* .indexbox-ratio-300 {
  padding-top: 360px !important;
} */
</style>
